import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: 'Bangers',
    fontSize: '1.75rem',
    padding: theme.spacing(0, 0, 0, 1),
    flexGrow: 1,
    alignItems: 'center',
    display: 'flex',
    textDecoration: 'none',
    color: 'lightpink',
    backgroundColor: theme.palette.background.default,
    '&:hover': {
        backgroundColor: '#fff',
        color: '#3c52b5',
    }
  },
}));

const MenuItemsLower = () => {
    const classes = useStyles();
    return (
        <>
        <Typography component={Link} to="/dashboard" variant="h6" className={classes.title}>
                account
        </Typography>
        <Typography component={Link} to="/contact" variant="h6" className={classes.title}>
                contact
        </Typography>
        </>
      );
}

export default MenuItemsLower

/*
        <Typography component={Link} to="/claim" variant="h6" className={classes.title}>
                claim
        </Typography>
*/