import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: "#F5F5F5",
    height: 500,
    border: 0,
    borderRadius: 3,
    padding: '0 30px',
    paddingLeft: '5%',
  },

  form: {
    backgroundColor: "#white",
    width: '250px',
  },

  welcome: {
    backgroundColor: theme.palette.background.default,
    paddingTop: '2%',
    height: 48,
  },

  support: {
    paddingTop: '20%',
    paddingLeft: '43%',
    backgroundColor: "#white",
    width: '250px',
  },

  info: {
    backgroundColor: theme.palette.background.default,
    paddingTop: '2%',
    paddingLeft: '5%', 
    width: 48,
  },

  progress: {
    paddingLeft: '5%', 
    paddingTop: '2%',
  },

  error: {
    paddingLeft: '20%', 
    paddingTop: '2%',
  },

  directions: {
    paddingLeft: '5%', 
    paddingTop: '0.5%',
    color: 'light gray', 
  },




}))