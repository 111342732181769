import React from 'react'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  
  root: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '2%',
    height: '80vh',
    fontFamily: 'Bangers',
  },

  blackText: {
    color: 'black',
    paddingTop: '4%',
  },
  colorText: {
    color: 'black',
    fontFamily: 'Roboto',
  },
  container: {
    textAlign: 'center',
  },
  subTitle: {
    color: '#fff',
    flexGrow: '1',
    fontSize: '1.5rem',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: "1.25rem",
    },
  },
}));

const Contact = ({user}) => {
    window.scrollTo(0, 0);
    const classes = useStyles();
    return (
      <div className={classes.root}>
      <div className={classes.container}>
      <h2 className={classes.subTitle}>
      <span className={classes.middleBackground}><span className={classes.blackText}>General</span></span>
      </h2>
      <h2 className={classes.subTitle}>
      <span className={classes.middleBackground}><span className={classes.colorText}>jml@animochi.io</span></span>
      </h2>
      <h2 className={classes.subTitle}>
      <span className={classes.middleBackground}><span className={classes.blackText}>&nbsp;</span></span>
      </h2>
      <h2 className={classes.subTitle}>
      <span className={classes.middleBackground}><span className={classes.blackText}>Support</span></span>
      </h2>
      <h2 className={classes.subTitle}>
      <span className={classes.middleBackground}><span className={classes.colorText}>support@animochi.io</span></span>
      </h2>
    </div>
    </div>
    )
}

export default Contact

