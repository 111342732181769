import React, { Component } from 'react';
import Web3 from 'web3';

export default () => {
    let productDirectory = []
    let mint1 = [
        {
            id: "00001",
            headshotURL: "https://firebasestorage.googleapis.com/v0/b/akasha2-80a92.appspot.com/o/AvatarMarketPic%2F00001%2F00001.JPG?alt=media&token=163bd9f7-fc42-4de4-8163-817825d76586",
            tokenID: "1",
            name: "one",
            claimed: "false"
        }
    ]
    let mint2 = [
        {
            id: "00002",
            headshotURL: "https://firebasestorage.googleapis.com/v0/b/akasha2-80a92.appspot.com/o/AvatarMarketPic%2F00002%2F00002.JPG?alt=media&token=a2b968d5-960a-40ad-989c-45f96d2932c2",
            tokenID: "2",
            name: "two",
            claimed: "false"
        }
    ]

    let mint3 = [
        {
            id: "00003",
            headshotURL: "https://firebasestorage.googleapis.com/v0/b/akasha2-80a92.appspot.com/o/AvatarMarketPic%2F00003%2F00003.JPG?alt=media&token=ad1c0be3-b282-44b8-870d-dde98758f39e",
            tokenID: "3",
            name: "three",
            claimed: "false"
        }
    ]

    let mint4 = [
        {
            id: "00004",
            headshotURL: "https://firebasestorage.googleapis.com/v0/b/akasha2-80a92.appspot.com/o/AvatarMarketPic%2F00004%2F00004.jpg?alt=media&token=3f8f4081-a2aa-423b-bdd2-f5b1e9a275e6",
            tokenID: "4",
            name: "four",
            claimed: "false"
        }
    ]

    let mint5 = [
        {
            id: "00005",
            headshotURL: "https://firebasestorage.googleapis.com/v0/b/akasha2-80a92.appspot.com/o/AvatarMarketPic%2F00005%2F00005.JPG?alt=media&token=f88189a6-acb6-483c-b65a-1c4e19b4d8f0",
            tokenID: "5",
            name: "five",
            claimed: "false"
        }
    ]

    let mint6 = [
        {
            id: "00006",
            headshotURL: "https://firebasestorage.googleapis.com/v0/b/akasha2-80a92.appspot.com/o/AvatarMarketPic%2F00006%2F00006.jpg?alt=media&token=4c45d89f-537f-472a-89bd-3dfd14b5a281",
            tokenID: "6",
            name: "six",
            claimed: "false"
        }
    ]

    let mint7 = [
        {
            id: "00007",
            headshotURL: "https://firebasestorage.googleapis.com/v0/b/akasha2-80a92.appspot.com/o/AvatarMarketPic%2F00007%2F00007.jpg?alt=media&token=20581010-dd27-41af-82a2-9b93a14c961b",
            tokenID: "7",
            name: "seven",
            claimed: "false"
        }
    ]

    let mint8 = [
        {
            id: "00008",
            headshotURL: "https://firebasestorage.googleapis.com/v0/b/akasha2-80a92.appspot.com/o/AvatarMarketPic%2F00008%2F00008.jpg?alt=media&token=43500d03-8c72-442c-b8ec-165723717793",
            tokenID: "8",
            name: "eight",
            claimed: "false"
        }
    ]

    let mint9 = [
        {
            id: "00009",
            headshotURL: "https://firebasestorage.googleapis.com/v0/b/akasha2-80a92.appspot.com/o/AvatarMarketPic%2F00009%2F00009.JPG?alt=media&token=31eacf60-1c57-4cbb-a372-82792753ca43",
            tokenID: "9",
            name: "nine",
            claimed: "false"
        }
    ]

    let mint10 = [
        {
            id: "00010",
            headshotURL: "https://firebasestorage.googleapis.com/v0/b/akasha2-80a92.appspot.com/o/AvatarMarketPic%2F00010%2F00010.JPG?alt=media&token=90522e69-ebc4-42e2-bf81-8409baba354d",
            tokenID: "10",
            name: "ten",
            claimed: false
        }
    ]

    let mint11 = [
        {
            id: "00011",
            headshotURL: "https://firebasestorage.googleapis.com/v0/b/akasha2-80a92.appspot.com/o/AvatarMarketPic%2F00011%2F00011.jpg?alt=media&token=6eb761b1-ee09-4e81-adde-f1a208e81f95",
            tokenID: "11",
            name: "eleven",
            claimed: "false"
        }
    ]

    let mint12 = [
        {
            id: "00012",
            headshotURL: "https://firebasestorage.googleapis.com/v0/b/akasha2-80a92.appspot.com/o/AvatarMarketPic%2F00012%2F00012.jpg?alt=media&token=22ef2d96-00cf-4bf8-bbfe-8d1b083b47f4",
            tokenID: "12",
            name: "Twelve",
            claimed: "false"
        }
    ]

    let mint13 = [
        {
            id: "00013",
            headshotURL: "https://firebasestorage.googleapis.com/v0/b/akasha2-80a92.appspot.com/o/AvatarMarketPic%2F00013%2F00013.JPG?alt=media&token=413ed6f0-257b-4c73-b9d5-c2a6cb08400e",
            tokenID: "13",
            name: "Thirteen",
            claimed: "false"
        }
    ]

    let mint14 = [
        {
            id: "00014",
            headshotURL: "https://firebasestorage.googleapis.com/v0/b/akasha2-80a92.appspot.com/o/AvatarMarketPic%2F00014%2F00014.jpg?alt=media&token=e1236237-7eb2-4acb-bc7e-e46881909d4c",
            tokenID: "14",
            name: "Fourteen",
            claimed: "false"
        }
    ]

    let mint15 = [
        {
            id: "00015",
            headshotURL: "https://firebasestorage.googleapis.com/v0/b/akasha2-80a92.appspot.com/o/AvatarMarketPic%2F00015%2F00015.jpg?alt=media&token=fa068b7f-dd2a-4725-b958-c53f59ba73ee",
            tokenID: "15",
            name: "Fifteen",
            claimed: "false"
        }
    ]

    let mint16 = [
        {
            id: "00016",
            headshotURL: "https://firebasestorage.googleapis.com/v0/b/akasha2-80a92.appspot.com/o/AvatarMarketPic%2F00016%2F00016.JPG?alt=media&token=e54958dc-f257-4e7b-87aa-8959ca75b86c",
            tokenID: "16",
            name: "Sixteen",
            claimed: "false"
        }
    ]

    let mint17 = [
        {
            id: "00017",
            headshotURL: "https://firebasestorage.googleapis.com/v0/b/akasha2-80a92.appspot.com/o/AvatarMarketPic%2F00017%2F00017.jpg?alt=media&token=ae38c08c-7a78-4693-bb03-59ff04751954",
            tokenID: "17",
            name: "Seventeen",
            claimed: "false"
        }
    ]

    let mint18 = [
        {
            id: "00018",
            headshotURL: "https://firebasestorage.googleapis.com/v0/b/akasha2-80a92.appspot.com/o/AvatarMarketPic%2F00018%2F00018.JPG?alt=media&token=5c423b17-2f2b-448f-bcb5-f06772e2fbd8",
            tokenID: "18",
            name: "Eighteen",
            claimed: "false"
        }
    ]

    let mint19 = [
        {
            id: "00019",
            headshotURL: "https://firebasestorage.googleapis.com/v0/b/akasha2-80a92.appspot.com/o/AvatarMarketPic%2F00019%2F00019.JPG?alt=media&token=fdc16bf4-eb3f-4b42-a3fe-e8772334fdb3",
            tokenID: "19",
            name: "Nineteen",
            claimed: "false"
        }
    ]

    let mint20 = [
        {
            id: "00020",
            headshotURL: "https://firebasestorage.googleapis.com/v0/b/akasha2-80a92.appspot.com/o/AvatarMarketPic%2F00020%2F00020.JPG?alt=media&token=4eb9fd7a-05ac-44d3-9b3a-48b8d3032ce6",
            tokenID: "20",
            name: "Nineteen",
            claimed: "false"
        }
    ]

    
    
    
    
    productDirectory = [
        mint1, 
        mint2, 
        mint3, 
        mint4, 
        mint5, 
        mint6, 
        mint7, 
        mint8, 
        mint9,
        mint10,
        mint11,
        mint12,
        mint13,
        mint14,
        mint15, 
        mint16,
        mint17,
        mint18, 
        mint19,
        mint20
    ]

  return {productDirectory}
}