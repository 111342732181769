import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';

import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { Collapse } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    flex: 1,
    maxWidth: 645,
    background: 'lightblue',
    margin: '20px',
  },
  media: {
    height: 400,
    width: 220,
  },
  title: {
    fontFamily: 'Bold',
    fontWeight: 'bold',
    fontSize: '2rem',
    color: '#fff',
    height: 20,
  },
  desc: {
    fontFamily: 'Nunito',
    fontSize: '1.1rem',
    color: '#ddd',
  },
});

export default function VideoCard({ info, checked }) {
  const classes = useStyles();
  return (
    <Collapse in={checked} {...(checked ? { timeout: 1000 } : {})}>
      <Card className={classes.root}>
        <CardMedia
          component="video"
          muted
          autoPlay
          loop
          flex
          className={classes.media}
          image={info.imageUrl}
          title="Pose your model in app."
        />
      </Card>
    </Collapse>
  );
}