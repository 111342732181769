import React, { useState } from 'react';
import { Typography  } from '@material-ui/core';
import ProgressBar from './ProgressBar';
import useStyles from './styles';

const UploadForm = (auth) => {
    const classes = useStyles();
    const [file, setFile] = useState(null);
    const [error, setError] = useState(null);
    const [email, setEmail] = useState(null);
    const [ether, setEther] = useState(null);
    const [price, setPrice] = useState(null);
    const [code, setCode] = useState(null);
    var em = ''; 
    var et = '';
    var p = '';
    var c = '';
    var handled = false;

    const types = ['image/gif'];

    const handleChange = (e) => {
        if (handled) {
            return
        }
        let selected = e.target.files[0];
        if (selected && types.includes(selected.type) && auth && email != '' && ether != '') {
            setFile(selected);
            setError('');
            setEmail(em)
            setEther(et)
            setPrice(p)
            setCode(c)
            handled = true
        } else {
            setFile(null);
            setError('Please select an .gif file');
            setEmail(null)
            setEther(null)
            setPrice(null)
            setCode(null)
        }
    };

    const handleChange2 = (e) => {
        em = e.target.value
    };

    const handleChange3 = (e) => {
        et = e.target.value
    };

    const handleChange4 = (e) => {
        p = e.target.value
    };

    const handleChange5 = (e) => {
        c = e.target.value
    };

    return (
        <form className={classes.root}>
            <Typography 
                className={classes.welcome} 
                varian="h5" 
                style={{ backgroundColor:'#F5F5F5'}}
            >
                Authorized.
            </Typography>
            <Typography 
                className={classes.welcome} 
                varian="h5" 
                style={{ backgroundColor:'#F5F5F5'}}
            >
                Re-enter code: 
            </Typography>
            <input
                type='text'
                className={classes.form} 
                placeholder='code'
                onChange={handleChange5}
            /> 
            <Typography 
                className={classes.welcome} 
                varian="h5" 
                style={{ backgroundColor:'#F5F5F5'}}
            >
                Email: 
            </Typography>
            <input
                type='text'
                className={classes.form} 
                placeholder='email'
                onChange={handleChange2}
            /> 
            <Typography 
                className={classes.welcome} 
                varian="h5" 
                style={{ backgroundColor:'#F5F5F5'}}
            >
                *Ethereum Address:  
            </Typography>
            <input
                type='text'
                className={classes.form} 
                placeholder='address'
                onChange={handleChange3}
            />
            <Typography 
                className={classes.welcome} 
                varian="h5" 
                style={{ backgroundColor:'#F5F5F5'}}
            >
                **Sell price ($): 
            </Typography>
            <input
                type='text'
                className={classes.form} 
                placeholder='price'
                onChange={handleChange4}
            />
            <Typography 
                className={classes.welcome} 
                varian="h5" 
                style={{ backgroundColor:'#F5F5F5'}}
            >
                .Gif File:
            </Typography>      
            <label className={classes.form}>
                <input type="file" onChange={handleChange} />
            </label>
            <div className="output">
                { error && <div className="error">{ error }</div>}
                { file && <ProgressBar file={file} setFile={setFile} ether={ether} email={email} price={price} code={code} /> }
            </div>
            <Typography 
                className={classes.welcome} 
                varian="h5" 
                style={{ backgroundColor:'#F5F5F5'}}
            >
                *an ethereum address is needed for your direct payment.  If one is not given, you will be contacted via email with further instructions. 
            </Typography> 
            <Typography 
                className={classes.welcome} 
                varian="h5" 
                style={{ backgroundColor:'#F5F5F5'}}
            >
                **the price you provided will be converted to ethereum.   
            </Typography>

        </form>
        
  );
}

export default UploadForm;
