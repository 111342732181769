import React, { useRef, useState } from "react"
import { Form, Button, Card, Alert } from "react-bootstrap"
import { useAuth } from "../Contexts/AuthContext"
import { Link, useHistory } from "react-router-dom"
import firebase from 'firebase/app'
import { makeStyles } from '@material-ui/core/styles';
import Web3 from 'web3';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '2%',
    height: '10vh',
    fontFamily: 'Bangers',
  
  },

  heading: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '2%',
    height: '10vh',
    fontFamily: 'Bangers',
    fontSize: '3.5rem',
  }
}));

export default function Signup({ wallet }) {
  const classes = useStyles();
  window.scrollTo(0, 0);
  const emailRef = useRef()
  const passwordRef = useRef()
  const userRef = useRef()
  const passwordConfirmRef = useRef()
  const { signup } = useAuth()
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  
  async function handleSubmit(e) {
    e.preventDefault()
    var pasw=  /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{1,15}$/;
    //var pasw = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
  
    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError("Passwords do not match")
    }

    if (passwordRef.current.value.length <= 5){
      return setError("Passwords must be at least 6 characters.")
    }

    if (passwordRef.current.value.search(/[0-9]/) < 0){
      return setError("Passwords must contain a number.")
    }

    if (!isValid(passwordRef.current.value)){
      return setError("Passwords must contain a special character.")
    } 

    try {
      setError("")
      setLoading(true)
      await signup(emailRef.current.value, passwordRef.current.value)
      createUserInFirestore()
      history.push("/Dashboard")
    } catch {
      setError("Failed to create an account.")
    }
    setLoading(false)
  }

  function isValid(str){
    return /[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g.test(str);
   }

  async function createUserInFirestore(){
    /*
    var web3 = new Web3(new Web3.providers.HttpProvider('https://mainnet.infura.io/v3/2b2b1ac60e2649c1b83e9d67221fdc0a'));
    var w = web3.eth.accounts.create()
    let wallets = [w.address]
    */

    const db = firebase.firestore();
    db.collection("users").doc(firebase.auth().currentUser.uid).set({
      uid: firebase.auth().currentUser.uid,
      username: userRef.current.value,
      claimLimitReached: false,
     // localWallet: w.address,
      wallets: [],
      avatarIDs: []
    })

    /*
    db.collection("wallets").doc(firebase.auth().currentUser.uid).set({
      address: w.address,
      private: w.privateKey
    })
    */
    
  }

  return (
    <>
      <Card>
        <Card.Body>
          <h2 className={classes.heading}>Sign Up</h2>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form onSubmit={handleSubmit}>
            <Form.Group id="email">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" ref={emailRef} required />
            </Form.Group>
            <Form.Group id="username">
              <Form.Label>Username</Form.Label>
              <Form.Control type="username" ref={userRef} required />
            </Form.Group>
            <Form.Group id="password">
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" ref={passwordRef} required />
            </Form.Group>
            <Form.Group id="password-confirm">
              <Form.Label>Password Confirmation</Form.Label>
              <Form.Control type="password" ref={passwordConfirmRef} required />
            </Form.Group>
            <Button style={{color:"white", border:"black", backgroundColor: "lightpink", boxShadow: "5px 5px 3px rgba(46, 46, 46, 0.62)"}} disabled={loading} className="w-100" type="submit">
              Sign Up
            </Button>
          </Form>
        </Card.Body>
      </Card>
      <div className="w-100 text-center mb-2 mt-2">
        Already have an account? <Link to="/login">Log In</Link>
      </div>
    </>
  )
}