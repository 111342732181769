import Snake from "../../assets/Snake.mp4";
import Speed from "../../assets/Speed.mp4";
import Greet from "../../assets/Greet.PNG";

const info = [
    {
      title: 'Pose with friends.',
      imageUrl: Speed,
    },
    {
      title: 'Tell a new story.',
      imageUrl: Greet,
    },
    {
      title: 'Create anything.',
      imageUrl: Snake,
    },
  ];
  
  export default info;