import React, { useEffect, useState } from 'react';
import { makeStyles, createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { Collapse } from '@material-ui/core';
import { Link } from "react-router-dom"
import firebase from 'firebase/app'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '2%',
    height: 'auto',
    fontFamily: 'Bangers',
  },
  blackText: {
    color: 'black',
  },
  container: {
    textAlign: 'center',
  },
  subTitle: {
    color: '#fff',
    flexGrow: '1',
    fontSize: '1.5rem',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: "0.48rem",
    },
  },
}));

export default function MiddleLanding() {
  const classes = useStyles();
  const [checked, setChecked] = useState(false);
  useEffect(() => {
    setChecked(true);
  }, []);
  return (
    <div className={classes.root} id="header">
      <Collapse
        in={checked}
        {...(checked ? { timeout: 1000 } : {})}
        collapsedHeight={50}
      >
        <div className={classes.container}>
          <h2 className={classes.subTitle}>
          <span className={classes.middleBackground}><span className={classes.blackText}> Create your avatar's social story on vTell.</span></span>
          </h2>
          <h2 className={classes.subTitle}>
          <span className={classes.middleBackground}><span className={classes.blackText}>Build their virtual world frame by frame. Capture and edit your creations.</span></span>
          </h2>
          <h2 className={classes.subTitle}>
          <span className={classes.middleBackground}><span className={classes.blackText}>Bring their story to life.</span></span>
          </h2>
          <div className="w-100 text-center mt-4">
          <Link to="/about" style={{ color: 'lightpink'}}>Learn More</Link> 
          </div>
          <div className="w-100 text-center mt-2 mb-2">
          { firebase.auth().currentUser != null
          ? <Link to="/Dashboard" style={{ color: 'lightpink'}}>Account</Link> 
          : <Link to="/login" style={{ color: 'lightpink'}}>Login</Link> 
          } 
          </div>

        </div>
      </Collapse>
    </div>
  );
}