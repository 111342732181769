import React, { useRef, useState } from "react"
import { Form, Button, Card, Alert, Container } from "react-bootstrap"
import { Link } from "react-router-dom"
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '2%',
    height: '10vh',
    fontFamily: 'Bangers',
  },

  button: {
    fontFamily: 'Bangers',
    color: 'lightpink',
  },
}));


export default function UpdateWallet( {updateWallet} ) {
    const classes = useStyles();
    window.scrollTo(0, 0);
    const [error] = useState("")

    return (
        <Container className="d-flex align-items-center justify-content-center" style={{ minHeight: "75vh" }}>
        <div className="w-100" style={{ maxWidth: "400px" }} >
            <Card>
                <Card.Body>
                <h2 className="text-center mb-4">Add connected wallet to account?</h2>
                {error && <Alert style={{backgroundColor: '#D3D3D3'}} variant="danger">{error}</Alert>}
                    <Form>
                        <Button style={{color:"white", border:"black", backgroundColor: "lightpink", boxShadow: "5px 5px 3px rgba(46, 46, 46, 0.62)"}} onClick={updateWallet} className="w-100">
                             Add
                        </Button>
                    </Form>
                </Card.Body>
            </Card>
            
            <div className="w-100 text-center mt-2">
                 <Link style={{ color: "pink"}} variant="link" to="/Dashboard">Back</Link>
            </div>
         </div>
     </Container>
    )
}
