import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingTop: '2%',
      height: '90vh',
      fontFamily: 'Bangers',
    },
  }));

const AboutPage = () => {
    const classes = useStyles();
    window.scrollTo(0, 0);
    return (
        <div className={classes.root}>
            <div className="w-100 text-center mt-2">
                <a href='https://animochi.medium.com/a-little-help-on-buying-channels-3c457995d12a' style={{ color: 'black'}}>Web3</a>
            </div>
            <div className="w-100 text-center mt-2">
                <a href='https://animochi.medium.com/animochi-a-new-way-to-anime-62458b7a644c' style={{ color: 'black'}}>White Paper</a>
            </div>
            <div className="w-100 text-center mt-2">
                <a href='https://twitter.com/vTellApp' style={{ color: 'black'}}>Twitter</a>
            </div>
        </div>
        

    )
}

export default AboutPage