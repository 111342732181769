import React, { useState, Component } from 'react';
import { Container } from "react-bootstrap"
import Web3 from 'web3';
import Animochi from '../abis/Animochi.json'


import './App.css';
import Nfts from '../components/Products/Nfts'; 
import NoProducts from '../components/Products/NoProducts'; 
import MenuItems from './Navbar/MenuItems';
import MenuItemsLower from './Navbar/MenuItemsLower';
import Admin from './Navbar/Admin';

import { BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import UploadForm from './UploadForm';
import Contact from './Navbar/Contact';
import AboutPage from './Navbar/AboutPage';

import firebase from 'firebase/app';
import Signup from './Auth/Signup';
import Login from './Auth/Login';
import UpdatePassword from './Auth/UpdatePassword';
import UpdateWallet from './Auth/UpdateWallet';
import UploadAvatar from './Auth/UploadAvatar';
import ForgotPassword from './Auth/ForgotPassword';
import { AuthProvider } from './Contexts/AuthContext'
import Dashboard from './Landing/Dashboard'
import Landing from './Landing/Landing'
import MiddleLanding from './Landing/MiddleLanding'
import NoEthMiddleLanding from './Landing/NoEthMiddleLanding'
import InfoLanding from './Landing/InfoLanding'
import PrivateRoute from './PrivateRoute'
import MintLookUp from './MintLookUp';
import productDirectory from './Products/ProductDirectory';
import PrivacyPage from './Terms/PrivacyPage';
import Terms from './Terms/Terms';

let mints = MintLookUp();
let directory = productDirectory();


class App extends Component { 

  async componentWillMount() {
    await this.loadWeb3()
    await this.loadBlockchainData()
    await this.updateAccount()
  }

  async loadWeb3() {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum)
      await window.ethereum.enable()
    }
    else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider)
    }
    else {
     // window.alert('Non-Ethereum browser detected. You should consider trying MetaMask!')
      var isPolygon = false 
      this.setState({isPolygon})
    }
  }

  async loadBlockchainData() {
    if (window.ethereum === false) {
      return
    }

    const web3 = window.web3
    if (web3 == null) {
      return
    }

    const accounts = await web3.eth.getAccounts()
    this.setState({ account: accounts[0] })
    window.ethereum.on('accountsChanged', function (accounts) {
      // Time to reload your interface with accounts[0]!
      this.setState({ account: accounts[0] })
     }.bind(this));

    const networkId = await web3.eth.net.getId()
    const animochiNetworkData = Animochi.networks[networkId]

    if (animochiNetworkData) {
      this.state.isPolygon = true 
      const animochiAbi = Animochi.abi
      const animochiAddress = animochiNetworkData.address

      const animochiContract = new web3.eth.Contract(animochiAbi, animochiAddress)
      this.setState({ animochiContract })
      
      const owner = await animochiContract.methods.deployer.call()
      this.setState( { owner })

      const animochiCount = await animochiContract.methods.totalSupply().call()
      this.setState({ animochiCount })
      const nftAddress = animochiAddress
      this.setState( { nftAddress })
      
      // Load products      
      for (var i = 1; i <= animochiCount; i++) {
        const product = await animochiContract.methods.products(i).call()
        this.setState({
          products2: [...this.state.products2, product]
        })


        console.log("Product Count")
        console.log(this.state.animochiCount)
        console.log("Products2")
        console.log(this.state.products2)
      }
      

      const uris = await animochiContract.methods.tokenURI(1).call()

      /*
      for (var i = 0; i < this.state.registeredWallets.length; i++){
        const currentAccount = this.state.registeredWallets[i]
        const ownedTokens = await animochiContract.methods.balanceOf(currentAccount).call()
        for(var h = 0; h < ownedTokens.toNumber(); h++) {
          const id =  await animochiContract.methods.tokenOfOwnerByIndex(currentAccount, h).call()
          const ownedMint = mints.mints[0][id.toString()]
          this.setState({
            mints: [...this.state.mints, ownedMint]
          })

          if (this.state.mints.length > 0){
            this.setState({
              claimLimitReached: true
            })
          }
        } 
      }
      */

      const currentWallets = this.state.registeredWallets
      currentWallets.push(animochiAddress)
      for (var i = 0; i < this.state.avatarTokens.length; i++){
        const token = this.state.avatarTokens[i];
        var holder = await animochiContract.methods.ownerOf(token).call();
        const exists = currentWallets.some(v => (v === holder));
        if (exists === true) {
          const ownedMint = mints.mints[0][token]
          this.setState({
            mints: [...this.state.mints, ownedMint]
          })
        }
        if (this.state.mints.length > 0){
          this.setState({
            claimLimitReached: true
          })
        }
      }

    } else {
      window.alert('Smart contract not deployed to detected network.')
      this.state.isPolygon = false
    }

  }

  async loadURLForProfileImages() {
    const db = firebase.firestore();
    var docRef = db.collection("users").doc(firebase.auth().currentUser.uid);
    docRef.get().then((doc) => {
      if (doc.exists) {
        let data = doc.data()
        var ava = data.avatarIDs
        for (var i = 0; i < (ava.length); i++) {
          var dRef = db.collection("avatars").doc(ava[i]);
          dRef.get().then((doc2) => {
            let data  = doc2.data()
            let url = data.channelCoverImageURL
            this.setState({
              avatarURLs: [...this.state.avatarURLs, url]
            })
          }).catch((error) => {
              console.log("Error URLs", error);
          });
        }
      } else {
        console.log("No such document!");
      }
    }).catch((error) => {
        console.log("Error getting document:", error);
    });
  }

  async updateAccount() {
    const user = firebase.auth().currentUser
    if (user === "" || user === "undefined" || user === null){
     // console.log("Account Not Updated")
      return
    }
    if (this.state.mints === undefined){
     // console.log("Account Not Updated")
      return
    }
    const db = firebase.firestore();
    db.collection('users').doc(firebase.auth().currentUser.uid).update({
     // avatarIDs: this.state.mints
    })
    
   //console.log("Account Updated")
  }
  
  mint = (name, modelNumber, url, price, owner, tokenID, headshot) => {  
    const user = firebase.auth().currentUser.uid
    if (this.state.account != this.state.owner && user != "" ){
      alert("Not Authorized")
      return 
    }
    this.state.animochiContract.methods.mint(name, modelNumber, url, owner, window.web3.utils.toWei(price, 'Ether'), tokenID, headshot).send({from: this.state.account})
    .on('confirmation', function(confNumber, receipt){
      const db = firebase.firestore();
      const tokenString = tokenID
      const tokenNum =  parseInt(tokenString , 10 );
      const mint = modelNumber
      const nameString = name
      const channelCoverImageURL = headshot
     // console.log(modelNumber, "minted")
      db.collection('avatars').doc(modelNumber).set({
        Name: nameString,
        ID: mint,
        TokenNumber: tokenNum,
        channelCoverImageURL: channelCoverImageURL,
        channelViews: 0
      })
    })
    
    
  }

  withdrawl = (amount) => {
    this.state.animochiContract.methods.Withdrawl(window.web3.utils.toWei(amount, 'Ether')).send({from: this.state.account})
    .once('receipt', (receipt) => {
      console.log("complete")
    }) 
  }
  
  deposit = (amount) => {
    this.state.animochiContract.methods.deposit(this.state.nftAddress).send({from: this.state.account, value: window.web3.utils.toWei(amount, 'Ether')})
    .once('receipt', (receipt) => {
    })
  }

  changeOwner = (address) => {
    this.state.animochiContract.methods.setOwner(address).send({from: this.state.account})
    .once('receipt', (receipt) => {
    })
  }

  ejectProduct = (product) => {
    const limitReached = this.state.claimLimitReached
    if (limitReached === true || this.state.mints > 0 ){
      alert("Sorry. Limit one avatar per account per drop.")
      return 
    }

    const web3 = window.web3
    const db = firebase.firestore();
    const user = firebase.auth().currentUser
    const mintCount = this.state.mints.length

    if (user === "" || user === "undefined" || user === null){
      alert("Login to an account to claim a CHANNEL.")
      return
    }

    this.state.animochiContract.methods.ejectProduct(this.state.account, product.tokenID, this.state.nftAddress).send({from: this.state.account})    
    .on('transactionHash', txHash => {
      const claimLimitReached = true
      this.setState( { claimLimitReached });
      db.collection('users').doc(firebase.auth().currentUser.uid).update({
        claimLimitReached: true
      })
    })
    
    .on('confirmation', function(confNumber, receipt){ 
      const token = product.tokenID
      const mint = product.id
      db.collection('users').doc(firebase.auth().currentUser.uid).update({
        avatarIDs: firebase.firestore.FieldValue.arrayUnion(mint),
        tokens: firebase.firestore.FieldValue.arrayUnion(token),
      })

      db.collection('avatars').doc(product.id).update({
        claimed: true
      })
    })
    .on('error', function(error){ 
      if (mintCount === 0){
        db.collection('users').doc(firebase.auth().currentUser.uid).update({
          claimLimitReached: false
        })
      }
     })
  }


   handleBuyNft = (purchasedProduct) => {
    this.state.animochiContract.methods.purchaseProduct(purchasedProduct.owner, purchasedProduct.id ).send({from: this.state.account, value: purchasedProduct.price})
    .on('confirmation', function(confNumber, receipt){ 
      const db = firebase.firestore();
      const modelID = purchasedProduct.tokenID
      const tokenID = purchasedProduct.id
      db.collection('users').doc(firebase.auth().currentUser.uid).update({
        avatarIDs: firebase.firestore.FieldValue.arrayUnion(modelID),
        avatarTokenID: firebase.firestore.FieldValue.arrayUnion(tokenID.toString())
      })
    })
  }

  handlePassword = (pass) => {
    if (this.state.passes.includes(pass)){
      this.setState({currentPass: pass});
      console.log(this.state.currentPass)
      //Delete From firebase
      let editPasses = this.state.passes
      var index = editPasses.indexOf(pass)
      if (index !== -1) {
        editPasses.splice(index, 1);
        this.setState({passes: editPasses});
      }
      firebase.firestore().collection("passwords").where("password", "==", pass).get()
      .then(querySnapshot => {
        querySnapshot.docs[0].ref.delete();
      });
      //Auth content
      let auth = true
      this.setState( { auth })
    } else {
      console.log("Incorrect")
    }
  }

  handleIn = (e) => {
    let password = e.target.value;
    this.setState( { password })
  };

  returnPasses = (e) => { 
  };

  getFirebaseData = (user) => {
    const db = firebase.firestore();
    var docRef = db.collection("users").doc(user.uid);
    docRef.get().then((doc) => {
      if (doc.exists) {
        let data = doc.data()
        let avatarIDs = data.avatarIDs;
        this.setState( { avatarIDs });
        console.log("avatarIDs")
        console.log(avatarIDs)
        let username = data.username;
        this.setState( { username });
        console.log("username")
        console.log(username)
        let registeredWallets = data.wallets;
        this.setState( { registeredWallets });
        let avatarTokens = data.Tokens;
        this.setState( { avatarTokens });
        let claimLimitReached = data.claimLimitReached;
        this.setState( { claimLimitReached });
        console.log("this.state")
        console.log(this.state)
      } else {
        console.log("No such document!");
      }
    }).catch((error) => {
        console.log("Error getting document:", error);
    });
    
    var docRef = db.collection("users").doc(firebase.auth().currentUser.uid);
    docRef.get().then((doc) => {
      if (doc.exists) {
        let data = doc.data()
        var ava = data.avatarIDs
        for (var i = 0; i < (ava.length); i++) {
          var dRef = db.collection("avatars").doc(ava[i]);
          dRef.get().then((doc2) => {
            let data  = doc2.data()
            let url = data.channelCoverImageURL
            this.setState({
              avatarURLs: [...this.state.avatarURLs, url]
            })
          }).catch((error) => {
              console.log("Error URLs", error);
          });
        }
        console.log("this.state")
        console.log(this.state)
      } else {
        console.log("No such document!");
      }
    }).catch((error) => {
        console.log("Error getting document:", error);
    });
  }

  createProductsFromFirebase = () => {
    var p = this.state.products
    let mintCount = Object.keys(this.state.products).length
    for(var i = 0; i <= (mintCount - 1); i++) {
      const db = firebase.firestore();
      var docRef = db.collection("avatars").doc(this.state.products[i][0].id);
      docRef.get().then((doc) => {
        if (doc.exists) {
          let data = doc.data()
          for (p in this.state.products){
            let currentProduct = this.state.products[p][0]
            if (currentProduct.id === data.ID) {
              let claimed = data.claimed;
              let products = [...this.state.products];
              let product = {...products[p]};
              product[0].claimed = claimed;
              products[p] = product;
              this.setState({products});
            }
          }
        } else {
          console.log("No such document!");
        }
      }).catch((error) => {
          console.log("Error getting document:", error);
      });
    } 
  }



  captureFile(event) {
    event.preventDefault()
    const file = event.target.files[0]
    const reader = new window.FileReader()
    reader.readAsArrayBuffer(file)
    reader.onloadend = () => {
      this.setState({ buffer: Buffer(reader.result) })
      console.log('buffer', this.state.buffer)
    }
  }

  
  updateVerifiedWallets = () => {
    const wallet = this.state.account
    if (wallet === "") {
      alert("Please connect a Web3 wallet to continue.")
      return
    }
    const web3 = window.web3
    const db = firebase.firestore();
    var self = this;
    db.collection("users")
      .where("wallets", "array-contains", wallet).get()
      .then(function(querySnapshot) {
          if (!querySnapshot.empty) {
              console.log("Document Exist");
              alert("ERROR: Wallet has already been added to an account.")
          } else {
             console.log("Document Doesn't Exist");
             let sign = web3.eth.personal.sign("Please sign to verify identity. Upon approval, this address will be added to your account.", wallet, "")
              .then(signature => {
                db.collection("users").doc(firebase.auth().currentUser.uid).update({
                  wallets: firebase.firestore.FieldValue.arrayRemove("None")
                })
                db.collection("users").doc(firebase.auth().currentUser.uid).update({
                  wallets: firebase.firestore.FieldValue.arrayUnion(wallet)
                })
                self.updateRegistered()
                alert("SUCCESS")
              });
          }
      });
  }

  updateRegistered = () => {
    let filteredArray = this.state.registeredWallets.filter(item => item !== "None")
    this.setState({registeredWallets: filteredArray});
    this.setState({
      registeredWallets: [...this.state.registeredWallets, this.state.account]
    })
  }

  constructor(props) {
    super(props)
    this.state = {
      account: '',
      owner: false,
      nftAddress: null,
      animochiContract: null,
      animochiCount: 0,
      products : directory.productDirectory,
      claimLimitReached: false,
      avatarIDs: [],
      avatarTokens: [],
      avatarURLs: [],
      registeredWallets: [],
      username: '',
      file: null,
      auth: false,
      passes: [],
      currentPass: '',
      purchasedProduct: null,
      buffer: null,
      mints: [],
      products2: [],
      isPolygon: false
    }
  }

  resetState = () => {
    const account = ''
    this.setState({account})
    const owner = false 
    this.setState({owner})
    const nftAddress = null
    this.setState({nftAddress})
    const animochiContract = null
    this.setState({animochiContract})
    const animochiCount = 0
    this.setState({animochiCount})
    const claimLimitReached = false
    this.setState({claimLimitReached})
    const avatarIDs = []
    this.setState({avatarIDs})
    const avatarURLs = []
    this.setState({avatarURLs})
    const registeredWallets = []
    this.setState({registeredWallets})
    const username = ''
    this.setState({username})
    const file = null
    this.setState({file})
    const auth = false
    this.setState({auth})
    const passes = []
    this.setState({passes})
    const currentPass = ''
    this.setState({currentPass})
    const purchasedProduct = null
    this.setState({purchasedProduct})
    const buffer = null
    this.setState({buffer})
    const mints = []
    this.setState({mints})
    const products2 = []
    this.setState({products2})
    const isPolygon = false
    this.setState({isPolygon})
  }

  render() {
    return (
      <Router>
        <AuthProvider handleUserData={this.getFirebaseData} createProducts={this.createProductsFromFirebase}>
        <div style={{ backgroundColor:'#F5F5F5', width: '100%'}}>
          <MenuItems />
          <Switch>
            <Route exact path="/">
                <Container className="d-flex align-items-center justify-content-center mt-5 mb-10" style={{ minHeight: '200px'}}>
                  <div className="w-800" style={{width: '100%', minHeight: '200px'}} >
                    <Landing />
                    <InfoLanding />
                    { this.state.account !== '' 
                    ? <MiddleLanding />
                    : <NoEthMiddleLanding /> 
                    } 
                  </ div>
                </Container>
            </Route>
            <PrivateRoute exact path="/Dashboard" component={Dashboard} username={this.state.username} avatarIDs={this.state.avatarIDs} avatarURLs={this.state.avatarURLs} registeredWallets={this.state.registeredWallets} resetState={this.resetState}/>
            <PrivateRoute exact path="/update-password" component={UpdatePassword}  />
            <Route exact path="/forgot-password" component={ForgotPassword}/>
            <Route exact path="/login">
              <Container className="d-flex align-items-center justify-content-center" style={{ minHeight: "100vh" }}>
                  <div className="w-100" style={{ maxWidth: "400px" }} >
                    <Login />
                  </ div>
              </Container>
            </Route>

            <Route exact path="/signup">
                <Container className="d-flex align-items-center justify-content-center" style={{ minHeight: "100vh" }}>
                  <div className="w-100" style={{ maxWidth: "400px" }} >
                    <Signup wallet={this.state.account} />
                  </ div>
                </Container>
            </Route>
            <Route exact path="/update-wallet">
                <Container className="d-flex align-items-center justify-content-center" style={{ minHeight: "100vh" }}>
                  <div className="w-100" style={{ maxWidth: "400px" }} >
                    <UpdateWallet wallet={this.state.account} updateWallet={this.updateVerifiedWallets} />
                  </ div>
                </Container>
            </Route>
            <Route exact path="/upload-avatar">
                <Container className="d-flex align-items-center justify-content-center" style={{ minHeight: "100vh" }}>
                  <div className="w-100" style={{ maxWidth: "400px" }} >
                    <UploadAvatar />
                  </ div>
                </Container>
            </Route>
            <Route exact path="/animochi">
                <Container className="d-flex align-items-center justify-content-center" style={{ minHeight: "0vh", maxHeight: "200vh" }}>
                  <div className="w-800" style={{ maxWidth: "1600px", maxHeight: "200vh" }} >
                    <Landing />
                    <InfoLanding />
                    { this.state.account !== '' 
                    ? <MiddleLanding />
                    : <NoEthMiddleLanding />
                    } 
                  </ div>
                </Container>
            </Route>

            <Route exact path="/create">
              <div style={{ backgroundColor:'#F5F5F5', width: '100%', height: '1000px'}} className="container-fluid mt-5"> 
              <div className="row">
                <main role="main" className="col-lg-12 d-flex text-left"> 
                  <div className="content mr-auto ml-auto">
                    <form onSubmit={(event) => {
                      event.preventDefault()
                      const password = this.password.value
                      this.handlePassword(password)
                    }}>
                    <input
                      type='text'
                      className='form-control mb-1'
                      placeholder='code'
                      ref={(input) => { this.password = input }}
                    />
                    <input
                      type='submit'
                      style={{ backgroundColor:'lightpink', width: '200px', height: '45px'}}
                      className='btn btn-block btn-secondary'
                      value='Enter'
                    />
                    </form>
                  </div>
                </main>
              </div>
              {this.state.auth && ( 
                <UploadForm auth={this.auth} />
              )}          
              </div>
            </Route>
            <Route exact path="/mint">
              <div style={{ backgroundColor:'#F5F5F5'}} className="container-fluid mt-5">
              <div style={{ backgroundColor:'#F5F5F5'}} className="row">
                <main role="main" className="col-lg-12 d-flex text-center">
                  <div style={{ backgroundColor:'#F5F5F5'}} className="content mr-auto ml-auto">
                    <h5>Withdrawl:</h5>
                    <form onSubmit={(event) => {
                      event.preventDefault()
                      const amountW = this.amountW.value
                      this.withdrawl(amountW)
                    }}>
                    <input
                      type='string'
                      className='form-control mb-1'
                      placeholder='Amount'
                      ref={(input) => { this.amountW = input }}
                    />
                    <input
                      type='submit'
                      className='btn btn-block btn-primary'
                      value='Withdrawl'
                    />
                    </form>
                    <h5>Deposit:</h5>
                    <form onSubmit={(event) => {
                      event.preventDefault()
                      const amount = this.amount.value
                      this.deposit(amount)
                    }}>
                    <input
                      type='string'
                      className='form-control mb-1'
                      placeholder='Amount'
                      ref={(input) => { this.amount = input }}
                    />
                    <input
                      type='submit'
                      className='btn btn-block btn-primary'
                      value='Deposit'
                    />
                    </form>
                    <h5>Change Owner:</h5>
                    <form onSubmit={(event) => {
                      event.preventDefault()
                      const address = this.address.value
                      this.changeOwner(address)
                    }}>
                    <input
                      type='string'
                      className='form-control mb-1'
                      placeholder='Address'
                      ref={(input) => { this.address = input }}
                    />
                    <input
                      type='submit'
                      className='btn btn-block btn-primary'
                      value='Submit'
                    />
                    </form>
                  </div>
                </main>
              </div>
              <hr/>
              <div style={{ backgroundColor:'#F5F5F5'}} className="row text-center">
                { this.state.products.map((product, key) => {
                  return(
                    <div key = {key} className = "col-md-3 mb-3">
                    <div>{product.name}</div>
                    </div>
                  )
                })}
              </div>
              </div>
            </Route>
            <Route exact path="/contact">
              <Contact />
            </Route>
            <Route exact path="/about">
              <AboutPage />
            </Route>
            <Route exact path="/privacy">
              <PrivacyPage />
            </Route>
            <Route exact path="/terms">
              <Terms />
            </Route>
          </Switch>
          <MenuItemsLower />
          { this.state.account === this.state.owner && window.web3 != null
          ? <Admin />
          : null
          } 
        </div>
        </AuthProvider>

      </Router>

    );
  }
}

export default App;

            /*
            <Route exact path="/claim">
            {  this.state.registeredWallets.includes(this.state.account) && this.state.isPolygon
            ? <Nfts products={this.state.products} onBuyNft={this.handleBuyNft} onEjectNft={this.ejectProduct} account={this.state.account} claimLimitReached={this.state.claimLimitReached}/>
            : <NoProducts user={this.state.account} isPolygon={this.state.isPolygon} products={this.state.products} />
            } 
            
            </Route>
            */
