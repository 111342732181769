import React, { useRef, useState } from "react"
import { Form, Button, Card, Alert, Container } from "react-bootstrap"
import { useAuth } from "../Contexts/AuthContext"
import { Link, useHistory } from "react-router-dom"
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '2%',
    height: '10vh',
    fontFamily: 'Bangers',
  },

  button: {
    fontFamily: 'Bangers',
    color: 'lightpink',
  },
}));

export default function UpdatePassword() {
    window.scrollTo(0, 0);
    const classes = useStyles();
    const passwordRef = useRef()
    const passwordConfirmRef = useRef()
    const { updatePassword } = useAuth()
    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)
    const history = useHistory()
  
    function handleSubmit(e) {
      e.preventDefault()
      if (passwordRef.current.value !== passwordConfirmRef.current.value) {
        return setError("Passwords do not match")
      }

      if (passwordRef.current.value.length <= 5){
        return setError("Passwords must be at least 6 characters.")
      }
  
      if (passwordRef.current.value.search(/[0-9]/) < 0){
        return setError("Passwords must contain a number.")
      }
  
      if (!isValid(passwordRef.current.value)){
        return setError("Passwords must contain a special character.")
      } 
  
      const promises = []
      setLoading(true)
      setError("")

      if (passwordRef.current.value) {
        promises.push(updatePassword(passwordRef.current.value))
      }
  
      Promise.all(promises)
        .then(() => {
          history.push("/")
        })
        .catch(() => {
          setError("Failed to update password")
        })
        .finally(() => {
          setLoading(false)
        })
    }

    function isValid(str){
      console.log("isValid")
      console.log(typeof(str))
      console.log(str)
      return /[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g.test(str);
     }
  
    return (
        <Container className="d-flex align-items-center justify-content-center" style={{ minHeight: "75vh" }}>
        <div className="w-100" style={{ maxWidth: "400px" }} >
            <Card>
                <Card.Body>
                <h2 className="text-center mb-4">Change Password</h2>
                {error && <Alert variant="danger">{error}</Alert>}
                    <Form onSubmit={handleSubmit}>
                         <Form.Group id="password">
                             <Form.Label>Password</Form.Label>
                             <Form.Control type="password" ref={passwordRef} required />
                         </Form.Group>
                         <Form.Group id="password-confirm">
                            <Form.Label>Password Confirmation</Form.Label>
                            <Form.Control type="password" ref={passwordConfirmRef} required />
                        </Form.Group>
                        <Button style={{color:"white", border:"black", backgroundColor: "lightpink", boxShadow: "5px 5px 3px rgba(46, 46, 46, 0.62)"}} disabled={loading} className="w-100" type="submit">
                             Update
                        </Button>
                    </Form>
                </Card.Body>
            </Card>
            <div className="w-100 text-center mt-2">
                 <Link style={{color:"lightpink"}} to="/dashboard">Back</Link>
            </div>
         </div>
     </Container>
    )
}
