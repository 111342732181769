import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  root: {
    // maxWidth: 345, original width style
    display: 'flex',
    flexGrow: 1,
    height: 200,
    width: 100,
  },

  dashMedia: {

    height: 200,
    width: 100,
  },


}))