import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingTop: '2%',
      height: '90vh',
      fontFamily: 'Bangers',
    },
  }));

const Terms = () => {
    const classes = useStyles();
    window.scrollTo(0, 0);
    return (
        <>
            <div>
            <div></div>
            <h1>
                <text>Terms of Service</text>
            </h1>
            <div></div>
            <h5>
                <text>1. Who Can Use Our Services.</text>
            </h5>
            <div></div>
            <text>vTell is not intended or targeted for use by anyone under the age of 13. You must be at least 13 years of age to access and use vTell. If you are under 18 years of age, you may use vTell under the supervision of a parent or legal guardian who agrees to be bound by these Terms.</text>
            <div></div>
            <h5>
                <text>2. User License </text>
            </h5>
            <div></div>
            <text>Subject to these Terms, vTell grants users of our services a personal, non-exclusive, non-transferable, non-sublicensable, revocable license to download, display, and use our services, including text, graphics, images, photographs, videos, data, displays, illustrations, and other content associated with vTell, for personal non-commercial use in connection with the services. This license does not include any right to, and you will not: (a) sell, resell or commercially use vTell; (b) copy, reproduce, distribute, publicly perform or publicly display vTell; (c) modify vTell, remove any proprietary rights notices or markings, or otherwise make any derivative uses of the app; (d) use any data mining, robots or similar data gathering or extraction methods on vTell; (e) use our services in any way that infringes on any intellectual property rights; and (f) use our services other than for their intended purposes.</text>
            <div></div>
            <div></div>
            <h5>
                <text>3. Prohibited Conduct</text>
            </h5>
            <text> In connection with your use of our Services, you will not:</text>
            <div></div>
            <text> a. Encourage or produce sexually explicit content. </text>
            <div></div>
            <div></div>
            <text> b. Encourage or engage in harassment, bullying or stalking conduct.  </text>
            <div></div>
            <div></div>
            <text> c. Encourage or engage in defamatory, discriminatory, or threatening behavior (including, but not limited to, threats of violence or harm). </text>
            <div></div>
            <div></div>
            <text> d. Impersonate another person or log into an account which you are not authorized to access.</text>
            <div></div>
            <div></div>
            <text> e. Engage or encourage the spread of false information. </text>
            <div></div>
            <div></div>
            <text> f. Use hate speech.</text>
            <div></div>
            <div></div>
            <text> g. Advocate or advance violent extremism or terrorism.</text>
            <div></div>
            <div></div>
            <text> h. Use vTell in any way that could interfere with, disrupt, negatively affect or inhibit other users or that could damage, disable, or impair the functioning of our services.</text>
            <div></div>
            <div></div>
            <text> i. Interfere or attempt to interfere with service or any user, host or network, including by way of introducing a virus, overloading, “flooding” or crashing, or sending unsolicited e-mail, promotions or advertisements.</text>
            <div></div>
            <div></div>
            <text> j. Reverse engineer, decompile, or disassemble any aspect of vTell or do anything that might discover source code or bypass or circumvent measures employed to prevent or limit access to any part of the Site.</text>
            <div></div>
            <div></div>
            <text> k. Collect or store personal information about other users.</text>
            <div></div>
            <div></div>
            <text> l. Develop or use any third-party applications that interact with our Services without our prior written consent.</text>
            <div></div>
            <div></div>
            <text>vTell reserves the right to classify, in our sole discretion, what conduct is prohibited and will not be permitted on the platform.</text>
            <div></div>
            <h5>
                <text>4. Termination</text>
            </h5>
            <text> If you are in breach of any of these Terms, we reserve the right, in our sole discretion, to terminate your right to access or use vTell. We are not responsible for any loss, damage or harm related to your inability to access vTell based on such termination.</text>
            <div></div>
            <h5>
                <text>5. Indemnification</text>
            </h5>
            <div></div>
            <text> To the fullest extent permitted by applicable law, you will indemnify, defend, and hold harmless vTell, our affiliates, and each of our agents and employees from and against any loss, liability, claim, demand, damages, expenses or costs (including reasonable legal fees) (“Claims”) arising out of or related to (a) your access to or use of our services; (b) your violation of these Terms; or (c) your conduct in connection with the Services. You agree to promptly notify us of any such Claims, cooperate with vTell in defending such Claims and pay all fees, costs and expenses associated with defending such Claims (including legal fees).  </text>
            <div></div>
            <h5>
                <text>6. Disclaimers and Limitation of Liability</text>
            </h5>
            <div></div>
            <text>We make no representations about the reliability of the features of vTell or other service, and disclaims all liability in the event of any service failure. You acknowledge that any reliance on such material or systems will be at your own risk.   </text>
            <div></div>
            <h5>
                <text>Contact Us </text>
            </h5>
            <text>Please contact us by email at support@animochi.io.</text>
            <div></div>
         </div>
        </>
    )
}

export default Terms