import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    // maxWidth: 345, original width style
    maxWidth: '100%',
    minWidth: '100%',

  },
  
  media: {
    height: 475,
    paddingTop: '56.25%', // 16:9
    [theme.breakpoints.down('sm')]: {
      height: 675,
    },
  },
  cardActions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },

  cardContent: {
    height: 20,
    display: 'flex',
    justifyContent: 'space-between',
    fontFamily: 'bangers',
    fontSize: 25,
  },
  
  soldLabel: {
    display: 'flex',
    paddingLeft: "8px",
    paddingBottom: "22px",
    justifyContent: 'space-between',
  },

  buyLabel: {
    display: 'flex',
    paddingLeft: "8px",
    paddingBottom: "10px",
    justifyContent: 'space-between',
    fontSize: 16,
  },

  ejectLabel: {
    display: 'flex',
    paddingLeft: "8px",
    paddingBottom: "10px",
    justifyContent: 'space-between',
    fontSize: 16,
    fontFamily: 'bangers'
  },
}));