import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import WelcomeCard from './WelcomeCard';
import VideoCard from './VideoCard';
import info from './Info';
import useWindowPosition from './useWindowPosition';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '1%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
    [theme.breakpoints.down('lg')]: {
      minHeight: '75vh',
    },
  },
}));

export default function InfoLanding() {
  const classes = useStyles();
  const checked = useWindowPosition('header');
  return (
    <div className={classes.root} id="Animochi Card">
      <VideoCard info={info[0]} checked={checked} />
      <WelcomeCard info={info[1]} checked={checked}/>
      <VideoCard info={info[2]} checked={checked} />
    </div>
  );
}

//<WelcomeCard info={info[1]} checked={checked} />
//<WelcomeCard info={info[2]} checked={checked} />