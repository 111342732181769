import React, { useRef, useState } from "react"
import { Form, Button, Card, Alert, Container, FormControl } from "react-bootstrap"
import { Link } from "react-router-dom"
import { makeStyles } from '@material-ui/core/styles';
import firebase from '../../firebase/firebase';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '2%',
    height: '10vh',
    fontFamily: 'Bangers',
  },

  button: {
    fontFamily: 'Bangers',
    color: 'lightpink',
  },
}));

export default function UploadAvatar( {user} ) {
    const storage = firebase.storage();
    const classes = useStyles();
    window.scrollTo(0, 0);
    const [error, setError] = useState("")
    const [fileName, setFileName] = useState("Upload Boundary File");
    const [loading, setLoading] = useState(false)
    const nameRef = useRef()

    async function handleSubmitVRM(e) { 
        e.preventDefault()
        const file = fileName
        const user = firebase.auth().currentUser.uid
        const ava = nameRef.current.value
        if(file == null){
            return;
        }
        storage.ref(`/AvatarUploads/${user}/${ava}`).put(file)
        .on("state_changed" , setError("Success.  Your avatar's CHANNEL will be available soon.") , alert);
    }

    return (
        <Container className="d-flex align-items-center justify-content-center" style={{ minHeight: "75vh" }}>
        <div className="w-100" style={{ maxWidth: "400px" }} >
            <Card>
                <Card.Body>
                <h2 className="text-center mb-4">Avatar Upload</h2>
                {error && <Alert style={{backgroundColor: '#D3D3D3'}} variant="danger">{error}</Alert>}
                    <Form onSubmit={handleSubmitVRM}>
                        <Form.Group id="modelname">
                            <Form.Label>Model Name</Form.Label>
                            <Form.Control type="modelname" ref={nameRef} required />
                        </Form.Group>
                        <Form.Group>
                            <FormControl type="file" onChange={(e) => setFileName(e.target.files[0])} accept=".vrm, .VRM" />
                        </Form.Group>
                        <Button disabled={loading} style={{color:"white", border:"black", backgroundColor: "lightpink", boxShadow: "5px 5px 3px rgba(46, 46, 46, 0.62)"}} className="w-100" type="submit">
                            Upload
                        </Button>
                    </Form>
                </Card.Body>
            </Card>
            
            <div className="w-100 text-center mt-2">
                 <Link style={{ color: "pink"}} variant="link" to="/Dashboard">Back</Link>
            </div>
         </div>
     </Container>
    )
}
