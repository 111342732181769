import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingTop: '2%',
      height: '90vh',
      fontFamily: 'Bangers',
    },
  }));

const AboutPage = () => {
    const classes = useStyles();
    window.scrollTo(0, 0);
    return (
        <>
            <div>
            <div></div>
            <h1>
                <text>Privacy Policy</text>
            </h1>
            <div></div>
            <div></div>
            <h6>
            <text>This policy applies to all information collected or submitted on the vTell app for iPhone and any other devices and platforms.</text>
            </h6>
            
            <div></div>
            <h5>
                <text>vTell collects two categories of information:</text>
            </h5>
            <div></div>
            <h5>
                <text>1. Information you provide.</text>
            </h5>
            <div></div>
            <text>When you interact with our services, we collect information that you provide to us. For example, new vTell accounts are created using the user's email addresses, username and password. Email addresses are only used for logging in, password resets, responding to emails that you initiate, and sending notifications that you request. We do no send promotional emails. We may also ask you to provide us with some additional information that will be publicly visible on our services, such as a profile pictures. Additionally, any information you send through our services, such as media used to create strips (pictures, gifs, videos). Other users who view your content can always save that content or copy it outside the app.</text>
            <div></div>
            <h5>
                <text>2. Information we collect when you use our services.</text>
            </h5>
            <div></div>
            <text>When you use our services, we collect information about which of those services you have used and how you have used them. For instance, our servers know what users that you follow, and which videos you have viewed.</text>
            <div></div>
            <div></div>
            <h2>
                <text>Usage Information.</text>
            </h2>
            <text> We collect information about your activity through our services. For example, we may collect information about how you interact with our services, such as which search queries you submit or which effects you use to make a post.</text>
            <div></div>
            <div></div>
            <h5>
                <text>Content Information.</text>
            </h5>
            <text> We may collect content you create on our services, such as a scene with computer generated models and the related metadata that is provided with the content.</text>
            <div></div>
            <h5>
                <text>Camera and Photos</text>
            </h5>
            <div></div>
            <text> Many of our services require us to collect images and other information from your devices camera and photos.</text>
            <div></div>
            <h5>
            <text>Facial Tracking Data</text>
            </h5>
            <div></div>
            <text>Some of our services utilize iPhone's facial tracking capabilities to allow you to control your avatar and create content.  Data obtained and/or used in the process of providing these services is not collected, recorded or stored by vTell or any third party.  </text>
            <div></div>
            <h5>
                <text>What do we do with the information we collect? </text>
            </h5>
            <text>Develop, operate, improve, deliver, maintain, and protect our products and services. Enhance the safety and security of our products and services. Enforce our Terms of Service and other usage policies and comply with legal requirements.</text>
            <div></div>
            <h5>
                <text>How We Share Information</text>
            </h5>
            <div></div>
            <text>Content you publish can be viewed by any user.  We may share information about you for legal, safety, and security reasons. We may share information about you if we reasonably believe that disclosing the information is needed to:</text>
            <div></div>
            <text>Comply with any valid legal process, governmental request, or applicable law, rule, or regulation.</text>
            <div></div>
            <text>investigate, remedy, or enforce potential Terms of Service violations.</text>
            <div></div>
            <text>Protect the rights, property, or safety of us, our users, or others.</text>
            <div></div>
            <text>Detect and resolve any fraud or security concerns.</text>
            <div></div>
            <text>We may also share with third parties that provide services to us or perform business purposes for us aggregated, non-personally identifiable, or de-identified information.</text>
            <div></div>
            <h5>
                <text>How Long We Keep Your Information</text>
            </h5>
            <div></div>
            <text>We store your basic account information—like your name and email address—as long as your account is active.</text>
            <div></div>
            <text>User generated content is stored on our services until deleted by the user in the app. Although our systems are designed to carry out our deletion practices automatically, we cannot promise that deletion will occur within a specific timeframe. There may be legal requirements to store your data and we may need to suspend those deletion practices if we receive valid legal process asking us to preserve content, if we receive reports of abuse or other Terms of Service violations, or if your account or content created by you is flagged by our automated systems for abuse or other Terms of Service violations. Finally, we may also retain certain information in backup for a limited period of time or as required by law. </text>
            <div></div>
            <text>Account deletion requests can be made to support@animochi.com.</text>
            <div></div>
            <h5>
                <text>Information for European Union Customers:</text>
            </h5>
            <text> By using vTell and providing your information, you authorize us to collect, use, and store your information outside of the European Union.</text>
            <div></div>
            <h5>
                <text>California Online Privacy Protection Act Compliance</text>
            </h5>
            <div></div>
            <text>We comply with the California Online Privacy Protection Act. We therefore will not distribute your personal information to outside parties without your consent.</text>
            <div></div>
            <h5>
                <text>California Online Privacy Protection Act Compliance</text>
            </h5>
            <div></div>
            <text>Childrens Online Privacy Protection Act Compliance</text>
            <div></div>
            <text>We never collect or maintain information at our website from those we actually know are under 13, and no part of our website is structured to attract anyone under 13.</text>
            <div></div>
            <h5>
                <text>Your Consent</text>
            </h5>

            <div></div>
            <text>By using our site or apps, you consent to our privacy policy.</text>
            <div></div>
            <h5>
                <text>Contacting Us</text>
            </h5>
            <div></div>
            <text>If you have questions regarding this privacy policy, you may email jml@animochi.io.</text>
            <div></div>
            <h5>
                <text>Changes to this policy</text>
            </h5>
            <div></div>
            <text>If we decide to change our privacy policy, we will post those changes on this page. Summary of changes so far:</text>
            <div></div>
            <text>February 28, 2022: First published.</text>
         </div>
        </>
    )
}

export default AboutPage