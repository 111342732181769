import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Collapse } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import logo from '../../assets/Animochi.jpg'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 'true',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'auto',
    fontFamily: 'Modak',
    [theme.breakpoints.down('sm')]: {
      
    },
  },

  pinkBackground: {
   // background: 'lightpink',
    backgroundImage: "url(" + logo + ")",
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    height: 'auto',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.down('sm')]: {
      borderColor: 'black',
      borderRadius: 0.5,
      borderStyle: 'solid',
     
    },
  },

  smallText: {
    color: 'white',
    [theme.breakpoints.up('md')]: {
      borderColor: 'black',
      borderRadius: 0.5,
      borderStyle: 'solid',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: "1.5rem",
    },
  },

  bigText: {
    color: 'white',
    [theme.breakpoints.up('md')]: {
      borderColor: 'black',
      borderRadius: 0.5,
      borderStyle: 'solid',
      //fontSize: "10.55rem",
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: "5.55rem",
    },
  },
  container: {
    textAlign: 'center',
  },
  title: {
    color: '#fff',
    fontSize: '10.5rem',
    borderColor: 'black',
    borderWidth: 'black',
    height: 'auto',
    [theme.breakpoints.down('sm')]: {
      fontSize: '5.5rem',
      height: 'auto',
    },
  },

  subTitle: {
    color: '#fff',
    fontSize: '2.5rem',
    borderColor: 'black',
    borderWidth: 'black',
    height: 'auto',
    borderRadius: 0.5,
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
    },
  },

  goDown: {
    color: 'lightpink',
    fontSize: '4rem',
  },
}));

export default function Landing( ) {
  const classes = useStyles();
  const [checked, setChecked] = useState(false);
  useEffect(() => {
    setChecked(true);
  }, []);
  return (
    <div className={classes.root} id="header">
      <Collapse
        in={checked}
        {...(checked ? { timeout: 1000 } : {})}
        collapsedHeight={50}
      >
        <div className={classes.container}>
          <h1 className={classes.title}>
          <span className={classes.pinkBackground}><span className={classes.bigText}>&nbsp;vTell&nbsp;<span></span></span></span> 
          </h1>
          <h2 className={classes.subTitle}>
          <span className={classes.pinkBackground}><span className={classes.smallText}>&nbsp;Tell a new story&nbsp;</span></span>
          </h2>
          <ExpandMoreIcon className={classes.goDown} />
        </div>
      </Collapse>
    </div>
  );
}
