import React, { Component } from 'react'

export default () => {

    let mints = [
    {
        1: "00001",
        2: "00002",
        3: "00003",
        4: "00004",
        5: "00005",
        6: "00006",
        7: "00007",
        8: "00008",
        9: "00009",
        10: "00010",
        11: "00011",
        12: "00012",
        13: "00013",
        14: "00014",
        15: "00015",
        16: "00016",
        17: "00017",
        18: "00018",
        19: "00019",
        20: "00020",
      },
    ]
  return {mints}
}