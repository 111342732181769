import React, { useState } from "react"
import { Card, Button, Alert, Container } from "react-bootstrap"
import { useAuth } from "../Contexts/AuthContext"
import { Link, useHistory } from "react-router-dom"
import { makeStyles } from '@material-ui/core/styles';
import AvatarProfileCard from "./AvatarProfileCard";
import { Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  username: {
    fontSize: '5.5rem',
    textAlign: 'center',
    fontFamily: 'Bangers',
    [theme.breakpoints.down('sm')]: {
      fontSize: "3.5rem",
    },
  },
}));

export default function Dashboard( data ) {
  const classes = useStyles();
  window.scrollTo(0, 0);
  
  const [error, setError] = useState("")
  const { currentUser, logout } = useAuth()
  const history = useHistory()  


  var avatarIDs = data.avatarIDs.map((d, i) => <li key={i} value={d} >{d}</li>);
  if (avatarIDs.length > 0) {
    avatarIDs = avatarIDs.filter(item => item !== "None")
  } else if (avatarIDs.length == 0) {
    avatarIDs.push("None")
  }

  var urls = data.avatarURLs.map((d, i) => <li key={i} value={d} >{d}</li>);

  var registeredWallets = data.registeredWallets.map((d, i) => <li key={i} value={d} >{d}</li>);
  if (registeredWallets.length > 0) {
    registeredWallets = registeredWallets.filter(item => item !== "None")
  }
  /*
  if (registeredWallets.length == 0) {
    registeredWallets.push("None")
  }
  */

  async function handleLogout() {
    setError("")

    try {
      await logout()
      history.push("/login")
      data.resetState()
    } catch {
      setError("Failed to log out")
    }
  }

  return (
    <>
    <Container className="d-flex align-items-center justify-content-center" style={{ minHeight: "100vh", maxHeight: "300vh"}}>
        <div className="w-100" style={{ maxWidth: "475px", maxHeight: "300vh" }} >
        <Card >
            <Card.Body>
            <h2 className={classes.username}>{data.username}</h2>
            {error && <Alert variant="danger">{error}</Alert>}
                <div />
                  { urls.length == 0
                      ? <span>&nbsp;&nbsp;</span>
                      : <strong>Avatars:</strong>    
                  }
                  <div>
                  <Grid container justify = "center" spacing={3}>
                    { urls.length == 0
                      ? <></>
                      : urls.map((url) => (
                        <Grid item key={url} xs={12} sm={6} md={4} lg={3}>
                           <AvatarProfileCard url={url} />
                        </Grid>
                      ))
                    }
                 </Grid>
                  </div>
                <strong>Email:</strong> {currentUser.email}
                <div />
                { registeredWallets.length == 0
                      ? <></>
                      :  <strong>Wallets:</strong>   
                }
                { registeredWallets.length == 0
                      ? <></>
                      : <div> {registeredWallets } </div>
                }   
                <div />
                <Link to="/upload-avatar" style={{color:"white", border:"black", backgroundColor: "lightpink", boxShadow: "5px 5px 3px rgba(46, 46, 46, 0.62)"}} className={"btn btn-primary w-100 mt-3"}>
                    Upload Avatar
                </Link>
                <Link to="/update-wallet" style={{color:"white", border:"black", backgroundColor: "lightpink", boxShadow: "5px 5px 3px rgba(46, 46, 46, 0.62)"}} className={"btn btn-primary w-100 mt-3"}>
                    Add Wallet to Account
                </Link>
                <Link to="/update-password"  style={{color:"white", border:"black", backgroundColor: "lightpink", boxShadow: "5px 5px 3px rgba(46, 46, 46, 0.62)"}} className="btn btn-primary w-100 mt-3">
                    Change Password
                </Link>
            </Card.Body>
        </Card>    
 
        <div className="w-100 text-center mt-2">
            <Button style={{color:"white", border:"black", backgroundColor: "lightpink", boxShadow: "5px 5px 3px rgba(46, 46, 46, 0.62)"}} variant="link" onClick={handleLogout} className="mb-3">
                Log Out
            </Button>
        </div>
           
        </div>
    </Container>
    </>
  )
}
