import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import { Collapse } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 645,
    maxHeight: 500,
    background: 'lightgrey',
    margin: '20px',
  },
  media: {
    height: 540,
    width: 275,
    [theme.breakpoints.down('md')]: {
      height: 440,
      width: 220,
    },
    [theme.breakpoints.down('sm')]: {
      height: 440,
      width: 220,
    },
  },

  
  title: {
    fontFamily: 'Bold',
    fontWeight: 'bold',
    fontSize: '2rem',
    color: '#fff',
    height: 20,
  },
  desc: {
    fontFamily: 'Nunito',
    fontSize: '1.1rem',
    color: '#ddd',
  },
}));

export default function WelcomeCard({ info, checked }) {
  const classes = useStyles();

  return (
    <Collapse in={checked} {...(checked ? { timeout: 1000 } : {})}>
      <Card className={classes.root}>
        <CardMedia
          className={classes.media}
          image={info.imageUrl}
          title="Pose your model in app."
        />
      </Card>
    </Collapse>
  );
}
