import { SentimentSatisfiedAlt } from '@material-ui/icons';
import React, {useEffect} from 'react';
import useStorage from '../hooks/useStorage'
import useStyles from './styles';

const ProgressBar = ({ file, setFile, email, ether, price, code}) => {
    const classes = useStyles();
    const { url, progress } = useStorage(file, email, ether, price, code)

    useEffect(() => {
        if (url) {
          setFile(null);
        }
      }, [url, setFile]);
    
    return (
        <div className={classes.progress} style={{width: progress + '%'}}> {progress} </div>
    )
}
export default ProgressBar; 