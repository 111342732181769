import React from 'react';
import { Card, CardMedia} from '@material-ui/core';
import useStyles from './styles';

const AvatarProfileCard = ({ url }) => {
    const u = url.props.value
    const classes = useStyles();
    return (
        <Card className={classes.root}>
            <CardMedia className={classes.dashMedia} image={u} />
        </Card>
    )
}

export default AvatarProfileCard