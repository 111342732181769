import firebase from 'firebase/app'
import 'firebase/storage';
import 'firebase/firestore';

require('firebase/auth')


const app = firebase.initializeApp({
    apiKey: "AIzaSyBl0Ake2P9Z9HgreKCHaN2PbgdAWyvLwlk",
    authDomain: "akasha2-80a92.firebaseapp.com",
    projectId: "akasha2-80a92",
    storageBucket: "akasha2-80a92.appspot.com",
    messagingSenderId: "511588281518",
    appId: "1:511588281518:web:1bcdca7374d2f90dd6113f"
})



/*
const app = firebase.initializeApp({
    apiKey: "AIzaSyBHgk5JcV3QXZZFsCeuHWmgJGsMCY7Xt3g",
    authDomain: "akasha2dev.firebaseapp.com",
    projectId: "akasha2dev",
    storageBucket: "akasha2dev.appspot.com",
    messagingSenderId: "572362940810",
    appId: "1:572362940810:web:32c2098e5e433af515a873"
})
*/


const projectStorage = firebase.storage();
const projectFirestore = firebase.firestore();
const timestamp = firebase.firestore.FieldValue.serverTimestamp;

export { projectStorage, projectFirestore, timestamp };
export const authenticate = app.auth()
export default app