import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
    maxWidth: '100%',
  },
  noProducts: {
    flexGrow: 1,
    minHeight: '500px',
    height: '500px',
    backgroundColor: theme.palette.background.default,
  },
  error: {
    flexGrow: 1,
    fontFamily: 'bangers',
  },

  subTitle: {
    color: 'black',
    paddingTop: '2%',
    paddingBottom: '2%',
    flexGrow: '1',
    fontSize: '1.5rem',
    display: 'flex',
    flexWrap: 'wrap',
    fontFamily: 'bangers',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: "1.00rem",
    },
  },

  cardContent: {
    height: 20,
    display: 'flex',
    justifyContent: 'space-between',
    fontFamily: 'bangers',
    fontSize: 25,
  },
  media: {
    height: 475,
    paddingTop: '56.25%', // 16:9
    [theme.breakpoints.down('sm')]: {
      height: 675,
    },
  },

  dashMedia: {
    paddingTop: '56.25%', // 16:9
    height: 150,
    width: 60
  },

  root: {
    flexGrow: 1,
  },
}))